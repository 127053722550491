import React, { useState } from 'react';
import { 
  Cloud, Wind, Thermometer, Droplets, Search, Eye, 
  Gauge, Compass, Sun, CloudRain
} from 'lucide-react';
import { ForecastChart } from '../components/weather/ForecastChart';
import { WeatherPDF } from '../components/weather/WeatherPDF';

interface WeatherData {
  main: {
    temp: number;
    humidity: number;
    feels_like: number;
    pressure: number;
  };
  wind: {
    speed: number;
    deg: number;
  };
  weather: Array<{
    main: string;
    description: string;
    icon: string;
  }>;
  name: string;
  visibility: number;
  sys: {
    sunrise: number;
    sunset: number;
  };
  clouds: {
    all: number;
  };
}

interface ForecastData {
  list: Array<{
    dt: number;
    main: {
      temp: number;
      temp_min: number;
      temp_max: number;
      humidity: number;
    };
    wind: {
      speed: number;
    };
    pop: number;
    clouds: {
      all: number;
    };
    rain?: {
      "3h": number;
    };
    weather: Array<{
      description: string;
      icon: string;
    }>;
  }>;
}

export function Weather() {
  const [location, setLocation] = useState('');
  const [weather, setWeather] = useState<WeatherData | null>(null);
  const [forecast, setForecast] = useState<ForecastData | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const API_KEY = 'c6b99a8dc1a7bdcf328e07c5b24944be';

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!location.trim()) return;

    setLoading(true);
    setError(null);

    try {
      // Obtener clima actual
      const weatherResponse = await fetch(
        `https://api.openweathermap.org/data/2.5/weather?q=${location}&appid=${API_KEY}&units=metric&lang=es`
      );

      if (!weatherResponse.ok) {
        throw new Error('No se pudo encontrar la ubicación especificada');
      }

      const weatherData = await weatherResponse.json();
      setWeather(weatherData);

      // Obtener previsión
      const forecastResponse = await fetch(
        `https://api.openweathermap.org/data/2.5/forecast?q=${location}&appid=${API_KEY}&units=metric&lang=es`
      );

      if (!forecastResponse.ok) {
        throw new Error('Error al obtener la previsión');
      }

      const forecastData = await forecastResponse.json();
      setForecast(forecastData);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Error al obtener datos meteorológicos');
    } finally {
      setLoading(false);
    }
  };

  const getWindDirection = (degrees: number) => {
    const directions = ['N', 'NE', 'E', 'SE', 'S', 'SO', 'O', 'NO'];
    const index = Math.round(degrees / 45) % 8;
    return directions[index];
  };

  const formatTime = (timestamp: number) => {
    return new Date(timestamp * 1000).toLocaleTimeString('es-ES', {
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const formatHourlyData = () => {
    if (!forecast) return [];
    
    return forecast.list.slice(0, 8).map(item => ({
      time: formatTime(item.dt),
      temp: Math.round(item.main.temp),
      windSpeed: Math.round(item.wind.speed * 3.6),
      precipitation: item.pop * 100,
      clouds: item.clouds.all,
      rain: item.rain?.["3h"] || 0
    }));
  };

  return (
    <div className="space-y-8">
      {/* Banner */}
      <div className="relative h-[300px] -mx-4 -mt-4 overflow-hidden rounded-xl">
        <img
          className="w-full h-full object-cover"
          src="https://images.unsplash.com/photo-1534088568595-a066f410bcda?auto=format&fit=crop&q=80&w=2070"
          alt="Clima"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-blue-900/90 to-blue-800/70"></div>
        <div className="absolute inset-0 flex items-center px-8">
          <div className="max-w-3xl">
            <h1 className="text-4xl font-bold text-white mb-4">
              Meteorología para Vuelos
            </h1>
            <p className="text-xl text-blue-100">
              Consulta las condiciones meteorológicas para planificar tus operaciones
            </p>
          </div>
        </div>
      </div>

      {/* Search Form */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <form onSubmit={handleSearch} className="flex gap-4">
          <div className="flex-grow">
            <input
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              placeholder="Buscar ubicación..."
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <button
            type="submit"
            disabled={loading}
            className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50"
          >
            {loading ? (
              <div className="animate-spin h-5 w-5 border-2 border-white border-t-transparent rounded-full" />
            ) : (
              <Search className="w-5 h-5" />
            )}
          </button>
        </form>

        {error && (
          <div className="mt-4 p-4 bg-red-50 text-red-600 rounded-lg">
            {error}
          </div>
        )}
      </div>

      {/* Weather Display */}
      {weather && forecast ? (
        <div className="space-y-6">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold text-gray-900">
              Previsión meteorológica para {location}
            </h2>
            <WeatherPDF weather={weather} forecast={forecast} location={location} />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {/* Temperature */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center">
                  <Thermometer className="w-6 h-6 text-orange-500 mr-2" />
                  <h3 className="text-lg font-semibold text-gray-700">Temperatura</h3>
                </div>
                <img
                  src={`http://openweathermap.org/img/wn/${weather.weather[0].icon}@2x.png`}
                  alt={weather.weather[0].description}
                  className="w-12 h-12"
                />
              </div>
              <div className="text-3xl font-bold text-gray-900 mb-2">
                {Math.round(weather.main.temp)}°C
              </div>
              <p className="text-gray-600">
                Sensación térmica: {Math.round(weather.main.feels_like)}°C
              </p>
              <p className="text-gray-600 mt-2 capitalize">
                {weather.weather[0].description}
              </p>
            </div>

            {/* Wind */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <div className="flex items-center mb-4">
                <Wind className="w-6 h-6 text-blue-500 mr-2" />
                <h3 className="text-lg font-semibold text-gray-700">Viento</h3>
              </div>
              <div className="text-3xl font-bold text-gray-900 mb-2">
                {Math.round(weather.wind.speed * 3.6)} km/h
              </div>
              <p className="text-gray-600">
                Dirección: {getWindDirection(weather.wind.deg)}
              </p>
              <div className="mt-2">
                <Compass 
                  className="w-8 h-8 text-blue-500" 
                  style={{ transform: `rotate(${weather.wind.deg}deg)` }}
                />
              </div>
            </div>

            {/* Visibility and Clouds */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <div className="flex items-center mb-4">
                <Eye className="w-6 h-6 text-purple-500 mr-2" />
                <h3 className="text-lg font-semibold text-gray-700">Visibilidad</h3>
              </div>
              <div className="text-3xl font-bold text-gray-900 mb-2">
                {(weather.visibility / 1000).toFixed(1)} km
              </div>
              <p className="text-gray-600">
                Nubosidad: {weather.clouds.all}%
              </p>
              <div className="mt-2">
                <Cloud className={`w-8 h-8 ${weather.clouds.all > 50 ? 'text-gray-500' : 'text-blue-300'}`} />
              </div>
            </div>

            {/* Pressure and Humidity */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <div className="flex items-center mb-4">
                <Gauge className="w-6 h-6 text-green-500 mr-2" />
                <h3 className="text-lg font-semibold text-gray-700">Presión</h3>
              </div>
              <div className="text-3xl font-bold text-gray-900 mb-2">
                {weather.main.pressure} hPa
              </div>
              <p className="text-gray-600">
                Humedad: {weather.main.humidity}%
              </p>
              <div className="mt-2">
                <Droplets className={`w-8 h-8 ${weather.main.humidity > 70 ? 'text-blue-500' : 'text-blue-300'}`} />
              </div>
            </div>
          </div>

          {/* Sun Times */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <div className="flex items-center mb-4">
              <Sun className="w-6 h-6 text-yellow-500 mr-2" />
              <h3 className="text-lg font-semibold text-gray-700">Horario Solar</h3>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-gray-600">Amanecer</p>
                <p className="text-xl font-semibold">{formatTime(weather.sys.sunrise)}</p>
              </div>
              <div>
                <p className="text-gray-600">Atardecer</p>
                <p className="text-xl font-semibold">{formatTime(weather.sys.sunset)}</p>
              </div>
            </div>
          </div>

          {/* 5-Day Forecast */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <h3 className="text-xl font-semibold mb-6">Previsión 5 días</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
              {forecast?.list
                .filter((item, index) => index % 8 === 0)
                .slice(0, 5)
                .map((day) => (
                  <div key={day.dt} className="border rounded-lg p-4">
                    <p className="text-lg font-semibold text-center">
                      {new Date(day.dt * 1000).toLocaleDateString('es-ES', {
                        weekday: 'long',
                        day: 'numeric'
                      })}
                    </p>
                    <div className="flex justify-center my-2">
                      <img
                        src={`http://openweathermap.org/img/wn/${day.weather[0].icon}@2x.png`}
                        alt={day.weather[0].description}
                        className="w-12 h-12"
                      />
                    </div>
                    <div className="text-center space-y-2">
                      <p className="text-sm font-semibold">
                        {Math.round(day.main.temp_max)}° / {Math.round(day.main.temp_min)}°
                      </p>
                      <p className="text-xs text-gray-600 capitalize">
                        {day.weather[0].description}
                      </p>
                      <div className="flex items-center justify-center text-xs text-gray-600">
                        <Wind className="w-3 h-3 mr-1" />
                        {Math.round(day.wind.speed * 3.6)} km/h
                      </div>
                      <div className="flex items-center justify-center text-xs text-gray-600">
                        <CloudRain className="w-3 h-3 mr-1" />
                        {Math.round(day.pop * 100)}%
                      </div>
                      <div className="flex items-center justify-center text-xs text-gray-600">
                        <Droplets className="w-3 h-3 mr-1" />
                        {day.main.humidity}%
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          {/* Forecast Charts */}
          <div className="space-y-6">
            <div className="bg-white rounded-lg shadow-md p-6">
              <h3 className="text-lg font-semibold text-gray-700 mb-4">Previsión 24h</h3>
              <ForecastChart data={formatHourlyData()} />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
