import React from 'react';
import { 
  Clock, 
  PlaneTakeoff, 
  MapPin, 
  Plane,
  Loader2,
  AlertCircle,
  Sun,
  Moon,
  Calendar
} from 'lucide-react';
import { useStatistics } from '../hooks/useStatistics';
import { formatHours } from '../utils/timeFormat';
import { MonthlyStatsChart } from '../components/charts/MonthlyStatsChart';
import { FlightConditionsChart } from '../components/charts/FlightConditionsChart';
import { AircraftStatsChart } from '../components/charts/AircraftStatsChart';
import { DayNightChart } from '../components/charts/DayNightChart';

export function Statistics() {
  const { statistics, loading, error } = useStatistics();

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[calc(100vh-16rem)]">
        <div className="flex flex-col items-center space-y-4">
          <Loader2 className="h-8 w-8 animate-spin text-blue-600" />
          <p className="text-gray-600">Cargando estadísticas...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-[calc(100vh-16rem)]">
        <div className="text-center space-y-4">
          <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-red-100">
            <AlertCircle className="h-8 w-8 text-red-600" />
          </div>
          <p className="text-red-600">{error}</p>
          <button 
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Reintentar
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      {/* Banner */}
      <div className="relative h-[300px] -mx-4 -mt-4 overflow-hidden rounded-xl">
        <img
          className="w-full h-full object-cover"
          src="https://images.unsplash.com/photo-1551288049-bebda4e38f71?auto=format&fit=crop&q=80"
          alt="Statistics banner"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-blue-900/90 to-blue-800/70"></div>
        <div className="absolute inset-0 flex items-center px-8">
          <div className="max-w-3xl">
            <h1 className="text-4xl font-bold text-white mb-4">
              Estadísticas
            </h1>
            <p className="text-xl text-blue-100">
              Análisis detallado de tu actividad aérea
            </p>
          </div>
        </div>
      </div>

      {/* Total Stats */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <div className="bg-white rounded-xl p-6 border border-gray-200">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-blue-100 rounded-lg">
              <Clock className="h-5 w-5 text-blue-600" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Horas Totales</p>
              <p className="text-2xl font-bold text-gray-900">
                {formatHours(statistics.totalStats.hours)}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl p-6 border border-gray-200">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-green-100 rounded-lg">
              <PlaneTakeoff className="h-5 w-5 text-green-600" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Vuelos Realizados</p>
              <p className="text-2xl font-bold text-gray-900">
                {statistics.totalStats.flights}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl p-6 border border-gray-200">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-purple-100 rounded-lg">
              <MapPin className="h-5 w-5 text-purple-600" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Aeródromos</p>
              <p className="text-2xl font-bold text-gray-900">
                {statistics.totalStats.airports}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl p-6 border border-gray-200">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-amber-100 rounded-lg">
              <Plane className="h-5 w-5 text-amber-600" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Aeronaves</p>
              <p className="text-2xl font-bold text-gray-900">
                {statistics.totalStats.aircraft}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Monthly Stats Chart */}
      <div className="bg-white rounded-xl border border-gray-200 overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-lg font-semibold text-gray-900">Actividad Mensual</h2>
          <p className="text-sm text-gray-500">
            Horas de vuelo y operaciones por mes
          </p>
        </div>
        <div className="p-6">
          <MonthlyStatsChart stats={statistics.monthlyStats} />
        </div>
      </div>

      {/* Flight Conditions and Day/Night */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white rounded-xl border border-gray-200 overflow-hidden">
          <div className="p-6 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-gray-900">Condiciones de Vuelo</h2>
            <p className="text-sm text-gray-500">
              Distribución de vuelos por condiciones
            </p>
          </div>
          <div className="p-6">
            <FlightConditionsChart 
              vfr={statistics.flightConditions.vfr}
              ifr={statistics.flightConditions.ifr}
              vlos={statistics.flightConditions.vlos}
              evlos={statistics.flightConditions.evlos}
              bvlos={statistics.flightConditions.bvlos}
            />
          </div>
        </div>

        <div className="bg-white rounded-xl border border-gray-200 overflow-hidden">
          <div className="p-6 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-gray-900">Momento del Día</h2>
            <p className="text-sm text-gray-500">
              Distribución de vuelos diurnos y nocturnos
            </p>
          </div>
          <div className="p-6">
            <DayNightChart 
              day={statistics.flightConditions.day}
              night={statistics.flightConditions.night}
            />
          </div>
        </div>
      </div>

      {/* Aircraft Stats */}
      <div className="bg-white rounded-xl border border-gray-200 overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-lg font-semibold text-gray-900">Tipos de Aeronave</h2>
          <p className="text-sm text-gray-500">
            Horas y vuelos por tipo de aeronave
          </p>
        </div>
        <div className="p-6">
          <AircraftStatsChart stats={statistics.aircraftStats} />
        </div>
      </div>
    </div>
  );
}