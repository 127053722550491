import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, doc, setDoc, serverTimestamp, enableIndexedDbPersistence } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

// Initialize analytics only in production
export const analytics = import.meta.env.PROD ? getAnalytics(app) : null;

// Enable offline persistence only if supported
if (import.meta.env.PROD) {
  try {
    enableIndexedDbPersistence(db).catch((err) => {
      if (err.code === 'failed-precondition') {
        console.warn('Multiple tabs open, persistence can only be enabled in one tab at a time.');
      } else if (err.code === 'unimplemented') {
        console.warn('The current browser does not support persistence.');
      }
    });
  } catch (err) {
    console.warn('Error enabling persistence:', err);
  }
}

// Collection names
export const collections = {
  users: 'users',
  flights: 'flights',
  aircraft: 'aircraft',
  notifications: 'notifications',
  licenses: 'licenses',
  subscriptions: 'subscriptions'
} as const;

// Initialize collections
export const initializeCollections = async (userId: string) => {
  try {
    // Create user document if it doesn't exist
    const userRef = doc(db, collections.users, userId);
    await setDoc(userRef, {
      updatedAt: serverTimestamp(),
      initialized: true
    }, { merge: true });

    // Initialize user-specific collections
    for (const collectionName of Object.values(collections)) {
      if (collectionName === 'users') continue;

      const userCollectionRef = collection(db, `users/${userId}/${collectionName}`);
      const metadataRef = doc(userCollectionRef, '_metadata');
      
      await setDoc(metadataRef, {
        collectionName,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        initialized: true,
        userId
      }, { merge: true });
    }

    return true;
  } catch (error) {
    console.error('Error initializing collections:', error);
    throw error;
  }
};

export default app;