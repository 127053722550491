import React from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { WeatherData, ForecastData } from '../../types/weather';
import { Download } from 'lucide-react';
import '@fortawesome/fontawesome-free/css/all.min.css';

interface WeatherPDFProps {
  weather: WeatherData;
  forecast: ForecastData;
  location: string;
}

const getWeatherIcon = (code: string): string => {
  const iconMap: { [key: string]: string } = {
    '01d': 'fa-sun',
    '01n': 'fa-moon',
    '02d': 'fa-cloud-sun',
    '02n': 'fa-cloud-moon',
    '03d': 'fa-cloud',
    '03n': 'fa-cloud',
    '04d': 'fa-clouds',
    '04n': 'fa-clouds',
    '09d': 'fa-cloud-showers-heavy',
    '09n': 'fa-cloud-showers-heavy',
    '10d': 'fa-cloud-sun-rain',
    '10n': 'fa-cloud-moon-rain',
    '11d': 'fa-bolt',
    '11n': 'fa-bolt',
    '13d': 'fa-snowflake',
    '13n': 'fa-snowflake',
    '50d': 'fa-smog',
    '50n': 'fa-smog'
  };
  return iconMap[code] || 'fa-question';
};

export const WeatherPDF: React.FC<WeatherPDFProps> = ({ weather, forecast, location }) => {
  const generatePDF = async () => {
    const pdf = new jsPDF('p', 'mm', 'a4');
    
    // Crear un elemento temporal para el contenido del PDF
    const pdfContent = document.createElement('div');
    pdfContent.innerHTML = `
      <div style="padding: 40px; font-family: Arial, sans-serif; background: linear-gradient(135deg, #f0f9ff 0%, #ffffff 100%);">
        <!-- Header con logo y título -->
        <div style="text-align: center; margin-bottom: 30px; padding: 20px; background: linear-gradient(135deg, #1e40af 0%, #3b82f6 100%); border-radius: 15px; color: white;">
          <div style="font-size: 32px; font-weight: bold; margin-bottom: 10px;">
            <i class="fas fa-plane-departure" style="margin-right: 10px;"></i>
            Logbook.es
          </div>
          <div style="font-size: 20px;">Informe Meteorológico Detallado</div>
          <div style="margin-top: 15px; font-size: 16px;">
            ${new Date().toLocaleDateString('es-ES', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            })}
          </div>
        </div>

        <!-- Location Box -->
        <div style="background: white; padding: 20px; border-radius: 15px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); margin-bottom: 30px;">
          <div style="display: flex; align-items: center; justify-content: center;">
            <i class="fas fa-map-marker-alt" style="color: #1e40af; font-size: 24px; margin-right: 10px;"></i>
            <h2 style="color: #1e40af; font-size: 24px; margin: 0;">${location}</h2>
          </div>
        </div>

        <!-- Current Weather Box -->
        <div style="background: white; padding: 30px; border-radius: 15px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); margin-bottom: 30px;">
          <h3 style="color: #1e40af; font-size: 20px; margin-bottom: 20px; text-align: center;">Condiciones Actuales</h3>
          
          <div style="display: flex; justify-content: center; align-items: center; margin-bottom: 30px;">
            <i class="fas ${getWeatherIcon(weather.weather[0].icon)}" style="font-size: 48px; color: #3b82f6; margin-right: 20px;"></i>
            <div style="text-align: center;">
              <div style="font-size: 36px; font-weight: bold; color: #1e40af;">${Math.round(weather.main.temp)}°C</div>
              <div style="color: #666; font-size: 18px;">${weather.weather[0].description}</div>
            </div>
          </div>

          <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 20px;">
            <div style="text-align: center; padding: 15px; background: #f8fafc; border-radius: 10px;">
              <i class="fas fa-thermometer-half" style="color: #3b82f6; font-size: 20px;"></i>
              <div style="margin-top: 5px; color: #666;">Sensación</div>
              <div style="font-weight: bold; color: #1e40af;">${Math.round(weather.main.feels_like)}°C</div>
            </div>
            <div style="text-align: center; padding: 15px; background: #f8fafc; border-radius: 10px;">
              <i class="fas fa-tint" style="color: #3b82f6; font-size: 20px;"></i>
              <div style="margin-top: 5px; color: #666;">Humedad</div>
              <div style="font-weight: bold; color: #1e40af;">${weather.main.humidity}%</div>
            </div>
            <div style="text-align: center; padding: 15px; background: #f8fafc; border-radius: 10px;">
              <i class="fas fa-wind" style="color: #3b82f6; font-size: 20px;"></i>
              <div style="margin-top: 5px; color: #666;">Viento</div>
              <div style="font-weight: bold; color: #1e40af;">${Math.round(weather.wind.speed * 3.6)} km/h</div>
            </div>
            <div style="text-align: center; padding: 15px; background: #f8fafc; border-radius: 10px;">
              <i class="fas fa-compress-arrows-alt" style="color: #3b82f6; font-size: 20px;"></i>
              <div style="margin-top: 5px; color: #666;">Presión</div>
              <div style="font-weight: bold; color: #1e40af;">${weather.main.pressure} hPa</div>
            </div>
          </div>
        </div>

        <!-- 5-Day Forecast -->
        <div style="background: white; padding: 30px; border-radius: 15px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
          <h3 style="color: #1e40af; font-size: 20px; margin-bottom: 20px; text-align: center;">Previsión 5 días</h3>
          <div style="display: grid; grid-template-columns: repeat(5, 1fr); gap: 15px;">
            ${forecast.list
              .filter((item, index) => index % 8 === 0)
              .slice(0, 5)
              .map(
                (day) => `
                <div style="text-align: center; padding: 15px; background: #f8fafc; border-radius: 10px;">
                  <div style="font-weight: bold; color: #1e40af; margin-bottom: 10px;">
                    ${new Date(day.dt * 1000).toLocaleDateString('es-ES', {
                      weekday: 'short',
                      day: 'numeric'
                    })}
                  </div>
                  <i class="fas ${getWeatherIcon(day.weather[0].icon)}" style="font-size: 24px; color: #3b82f6; margin: 10px 0;"></i>
                  <div style="font-weight: bold; color: #1e40af;">${Math.round(day.main.temp_max)}°/${Math.round(
                  day.main.temp_min
                )}°</div>
                  <div style="color: #666; font-size: 12px;">${day.weather[0].description}</div>
                  <div style="color: #666; font-size: 12px; margin-top: 5px;">
                    <i class="fas fa-wind" style="color: #3b82f6;"></i> ${Math.round(day.wind.speed * 3.6)} km/h
                  </div>
                  <div style="color: #666; font-size: 12px;">
                    <i class="fas fa-tint" style="color: #3b82f6;"></i> ${Math.round(day.pop * 100)}%
                  </div>
                </div>
              `
              )
              .join('')}
          </div>
        </div>

        <!-- Footer -->
        <div style="margin-top: 40px; text-align: center; padding: 20px; background: #f8fafc; border-radius: 15px;">
          <div style="color: #1e40af; font-weight: bold; margin-bottom: 5px;">© ${new Date().getFullYear()} Logbook.es</div>
          <div style="color: #666; font-size: 12px;">Tu diario de vuelo digital</div>
          <div style="color: #666; font-size: 12px; margin-top: 5px;">Datos proporcionados por OpenWeather</div>
        </div>
      </div>
    `;

    document.body.appendChild(pdfContent);

    try {
      const canvas = await html2canvas(pdfContent, {
        scale: 2,
        useCORS: true,
        logging: false,
        backgroundColor: '#f0f9ff'
      });

      const imgData = canvas.toDataURL('image/png');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`informe-meteorologico-${location.toLowerCase()}.pdf`);
    } finally {
      document.body.removeChild(pdfContent);
    }
  };

  return (
    <button
      onClick={generatePDF}
      className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
    >
      <Download className="w-5 h-5" />
      Exportar PDF
    </button>
  );
};
