import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { Flight } from '../types/flight';
import type { License } from '../types/license';
import { parseTimeString } from '../utils/timeFormat';

interface FlightStats {
  totalHours: number;
  monthlyHours: number;
  totalFlights: number;
  monthlyFlights: number;
  airports: number;
  newAirports: number;
  licenses: number;
  expiringLicenses: number;
  totalAircraft: number;
  newAircraft: number;
  recentAirports: Array<{ code: string; visits: number }>;
  flightConditions: {
    vfr: number;
    ifr: number;
    vlos: number;
    evlos: number;
    bvlos: number;
    day: number;
    night: number;
  };
}

interface AircraftStats {
  totalHours: number;
  totalFlights: number;
  lastFlight: string | null;
}

const defaultStats: FlightStats = {
  totalHours: 0,
  monthlyHours: 0,
  totalFlights: 0,
  monthlyFlights: 0,
  airports: 0,
  newAirports: 0,
  licenses: 0,
  expiringLicenses: 0,
  totalAircraft: 0,
  newAircraft: 0,
  recentAirports: [],
  flightConditions: {
    vfr: 0,
    ifr: 0,
    vlos: 0,
    evlos: 0,
    bvlos: 0,
    day: 0,
    night: 0
  }
};

export function useFlightStats() {
  const { user } = useAuth();
  const [stats, setStats] = useState<FlightStats>(defaultStats);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [aircraftStats, setAircraftStats] = useState<Record<string, AircraftStats>>({});

  useEffect(() => {
    let mounted = true;

    const fetchStats = async () => {
      if (!user?.uid) {
        if (mounted) {
          setLoading(false);
        }
        return;
      }

      try {
        // Get user's flights
        const flightsRef = collection(db, `users/${user.uid}/flights`);
        const flightsQuery = query(
          flightsRef,
          orderBy('date', 'desc')
        );
        
        const flightsSnapshot = await getDocs(flightsQuery);
        const flights: Flight[] = [];
        flightsSnapshot.forEach((doc) => {
          if (doc.id !== '_metadata') {
            flights.push({ id: doc.id, ...doc.data() } as Flight);
          }
        });

        // Get user's licenses
        const licensesRef = collection(db, `users/${user.uid}/licenses`);
        const licensesSnapshot = await getDocs(licensesRef);
        const licenses: License[] = [];
        licensesSnapshot.forEach((doc) => {
          if (doc.id !== '_metadata') {
            licenses.push({ id: doc.id, ...doc.data() } as License);
          }
        });

        // Calculate expiring licenses (within next 3 months)
        const today = new Date();
        const threeMonthsFromNow = new Date();
        threeMonthsFromNow.setMonth(today.getMonth() + 3);

        const expiringLicenses = licenses.filter(license => {
          const expiryDate = new Date(license.expiryDate);
          return expiryDate > today && expiryDate <= threeMonthsFromNow;
        }).length;

        // Calculate aircraft-specific stats
        const aircraftStatsMap: Record<string, AircraftStats> = {};
        flights.forEach(flight => {
          if (!flight.aircraftId) return;

          if (!aircraftStatsMap[flight.aircraftId]) {
            aircraftStatsMap[flight.aircraftId] = {
              totalHours: 0,
              totalFlights: 0,
              lastFlight: null
            };
          }

          const stats = aircraftStatsMap[flight.aircraftId];
          stats.totalHours += parseTimeString(flight.totalTime);
          stats.totalFlights += 1;
          
          if (!stats.lastFlight || new Date(flight.date) > new Date(stats.lastFlight)) {
            stats.lastFlight = flight.date;
          }
        });

        if (mounted) {
          setAircraftStats(aircraftStatsMap);
        }

        // Calculate global stats
        const now = new Date();
        const monthStart = new Date(now.getFullYear(), now.getMonth(), 1);
        
        // Calculate monthly flights
        const monthlyFlights = flights.filter(f => new Date(f.date) >= monthStart);
        
        // Calculate unique airports and visits
        const airportVisits = new Map<string, { visits: number; lastVisit: string }>();
        
        flights.forEach(flight => {
          // Process departure airport
          const depStats = airportVisits.get(flight.departureLocation) || { visits: 0, lastVisit: flight.date };
          depStats.visits += 1;
          if (new Date(flight.date) > new Date(depStats.lastVisit)) {
            depStats.lastVisit = flight.date;
          }
          airportVisits.set(flight.departureLocation, depStats);

          // Process arrival airport (only if different from departure)
          if (flight.arrivalLocation !== flight.departureLocation) {
            const arrStats = airportVisits.get(flight.arrivalLocation) || { visits: 0, lastVisit: flight.date };
            arrStats.visits += 1;
            if (new Date(flight.date) > new Date(arrStats.lastVisit)) {
              arrStats.lastVisit = flight.date;
            }
            airportVisits.set(flight.arrivalLocation, arrStats);
          }
        });

        // Get recent airports sorted by visits
        const recentAirports = Array.from(airportVisits.entries())
          .map(([code, stats]) => ({
            code,
            visits: stats.visits
          }))
          .sort((a, b) => b.visits - a.visits)
          .slice(0, 5);

        // Calculate flight conditions
        const conditions = flights.reduce((acc, flight) => {
          const condition = (flight.flightConditions || '').toLowerCase();
          const timeOfDay = (flight.timeOfDay || '').toLowerCase();
          
          acc[condition] = (acc[condition] || 0) + 1;
          acc[timeOfDay] = (acc[timeOfDay] || 0) + 1;
          
          return acc;
        }, {} as Record<string, number>);

        // Calculate total hours
        const totalHours = flights.reduce((acc, flight) => {
          return acc + parseTimeString(flight.totalTime);
        }, 0);

        // Calculate monthly hours
        const monthlyHours = monthlyFlights.reduce((acc, flight) => {
          return acc + parseTimeString(flight.totalTime);
        }, 0);

        if (mounted) {
          setStats({
            totalHours,
            monthlyHours,
            totalFlights: flights.length,
            monthlyFlights: monthlyFlights.length,
            airports: airportVisits.size,
            newAirports: monthlyFlights.filter(f => 
              !flights.find(of => 
                of.date < monthStart.toISOString() && 
                (of.departureLocation === f.departureLocation || of.arrivalLocation === f.arrivalLocation)
              )
            ).length,
            licenses: licenses.length,
            expiringLicenses,
            totalAircraft: new Set(flights.map(f => f.aircraftId)).size,
            newAircraft: 0,
            recentAirports,
            flightConditions: {
              vfr: conditions.vfr || 0,
              ifr: conditions.ifr || 0,
              vlos: conditions.vlos || 0,
              evlos: conditions.evlos || 0,
              bvlos: conditions.bvlos || 0,
              day: conditions.day || 0,
              night: conditions.night || 0
            }
          });
          setError(null);
        }
      } catch (error) {
        console.error('Error fetching stats:', error);
        if (mounted) {
          setError('Error al cargar las estadísticas. Por favor, intenta de nuevo.');
          setStats(defaultStats);
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    fetchStats();

    return () => {
      mounted = false;
    };
  }, [user?.uid]);

  const getAircraftStats = (aircraftId: string): AircraftStats => {
    return aircraftStats[aircraftId] || {
      totalHours: 0,
      totalFlights: 0,
      lastFlight: null
    };
  };

  return { 
    stats, 
    loading, 
    error,
    getAircraftStats
  };
}