import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { collection, query, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { Flight } from '../types/flight';

interface MonthlyStats {
  month: string;
  hours: number;
  flights: number;
}

interface AircraftStats {
  type: string;
  hours: number;
  flights: number;
}

interface AirportVisit {
  code: string;
  name: string;
  visits: number;
  lastVisit: string;
}

interface Statistics {
  monthlyStats: MonthlyStats[];
  aircraftStats: AircraftStats[];
  flightConditions: {
    vfr: number;
    ifr: number;
    vlos: number;
    evlos: number;
    bvlos: number;
    day: number;
    night: number;
  };
  airports: AirportVisit[];
  totalStats: {
    hours: number;
    flights: number;
    airports: number;
    aircraft: number;
  };
}

const defaultStats: Statistics = {
  monthlyStats: [],
  aircraftStats: [],
  flightConditions: {
    vfr: 0,
    ifr: 0,
    vlos: 0,
    evlos: 0,
    bvlos: 0,
    day: 0,
    night: 0
  },
  airports: [],
  totalStats: {
    hours: 0,
    flights: 0,
    airports: 0,
    aircraft: 0
  }
};

export function useStatistics() {
  const { user } = useAuth();
  const [statistics, setStatistics] = useState<Statistics>(defaultStats);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchStatistics() {
      if (!user?.uid) {
        setLoading(false);
        return;
      }

      try {
        // Get user's flights
        const flightsRef = collection(db, `users/${user.uid}/flights`);
        const flightsQuery = query(
          flightsRef,
          orderBy('date', 'desc')
        );
        
        const flightsSnapshot = await getDocs(flightsQuery);
        const flights: Flight[] = [];
        flightsSnapshot.forEach((doc) => {
          if (doc.id !== '_metadata') {
            flights.push({ id: doc.id, ...doc.data() } as Flight);
          }
        });

        // Calculate monthly stats
        const monthlyStats = new Map<string, { hours: number; flights: number }>();
        const now = new Date();
        for (let i = 0; i < 12; i++) {
          const date = new Date(now.getFullYear(), now.getMonth() - i, 1);
          const monthKey = date.toLocaleString('default', { month: 'short', year: 'numeric' });
          monthlyStats.set(monthKey, { hours: 0, flights: 0 });
        }

        // Calculate aircraft stats
        const aircraftMap = new Map<string, { hours: number; flights: number }>();
        
        // Calculate flight conditions and totals
        let totalHours = 0;
        const conditions = {
          vfr: 0,
          ifr: 0,
          vlos: 0,
          evlos: 0,
          bvlos: 0,
          day: 0,
          night: 0
        };

        // Calculate airport visits
        const airportVisits = new Map<string, { visits: number; lastVisit: string }>();

        // Process each flight
        flights.forEach(flight => {
          // Monthly stats
          const flightDate = new Date(flight.date);
          const monthKey = flightDate.toLocaleString('default', { month: 'short', year: 'numeric' });
          const monthStat = monthlyStats.get(monthKey);
          if (monthStat) {
            const [hours, minutes] = flight.totalTime.split(':').map(Number);
            monthStat.hours += hours + (minutes / 60);
            monthStat.flights += 1;
          }

          // Aircraft stats
          const aircraftStat = aircraftMap.get(flight.aircraftType) || { hours: 0, flights: 0 };
          const [hours, minutes] = flight.totalTime.split(':').map(Number);
          aircraftStat.hours += hours + (minutes / 60);
          aircraftStat.flights += 1;
          aircraftMap.set(flight.aircraftType, aircraftStat);

          // Total hours
          totalHours += hours + (minutes / 60);

          // Flight conditions
          conditions[flight.flightConditions.toLowerCase() as keyof typeof conditions] += 1;
          conditions[flight.timeOfDay.toLowerCase() as keyof typeof conditions] += 1;

          // Process airports
          [flight.departureLocation, flight.arrivalLocation].forEach(airport => {
            const stats = airportVisits.get(airport) || { visits: 0, lastVisit: flight.date };
            stats.visits += 1;
            if (new Date(flight.date) > new Date(stats.lastVisit)) {
              stats.lastVisit = flight.date;
            }
            airportVisits.set(airport, stats);
          });
        });

        setStatistics({
          monthlyStats: Array.from(monthlyStats.entries()).map(([month, stats]) => ({
            month,
            ...stats
          })).reverse(),
          aircraftStats: Array.from(aircraftMap.entries()).map(([type, stats]) => ({
            type,
            ...stats
          })),
          flightConditions: conditions,
          airports: Array.from(airportVisits.entries()).map(([code, stats]) => ({
            code,
            name: code, // You could add a proper airport name lookup here
            ...stats
          })).sort((a, b) => b.visits - a.visits),
          totalStats: {
            hours: totalHours,
            flights: flights.length,
            airports: airportVisits.size,
            aircraft: new Set(flights.map(f => f.aircraftRegistration)).size
          }
        });

        setError(null);
      } catch (error) {
        console.error('Error fetching statistics:', error);
        setError('Error al cargar las estadísticas. Por favor, intenta de nuevo.');
        setStatistics(defaultStats);
      } finally {
        setLoading(false);
      }
    }

    fetchStatistics();
  }, [user?.uid]);

  return { statistics, loading, error };
}