import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { formatHours } from '../../utils/timeFormat';

ChartJS.register(ArcElement, Tooltip, Legend);

interface FlightConditionsChartProps {
  vfr: number;
  ifr: number;
  vlos: number;
  evlos: number;
  bvlos: number;
}

export function FlightConditionsChart({ vfr, ifr, vlos, evlos, bvlos }: FlightConditionsChartProps) {
  const data = {
    labels: ['VFR', 'IFR', 'VLOS', 'EVLOS', 'BVLOS'],
    datasets: [
      {
        data: [vfr, ifr, vlos, evlos, bvlos],
        backgroundColor: [
          'rgba(59, 130, 246, 0.8)', // blue-500
          'rgba(16, 185, 129, 0.8)', // green-500
          'rgba(245, 158, 11, 0.8)', // amber-500
          'rgba(99, 102, 241, 0.8)', // indigo-500
          'rgba(236, 72, 153, 0.8)', // pink-500
        ],
        borderColor: [
          'rgba(59, 130, 246, 1)',
          'rgba(16, 185, 129, 1)',
          'rgba(245, 158, 11, 1)',
          'rgba(99, 102, 241, 1)',
          'rgba(236, 72, 153, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          padding: 20,
          usePointStyle: true,
        },
      },
      tooltip: {
        callbacks: {
          label: function(context: any) {
            const label = context.label || '';
            const value = context.raw || 0;
            return `${label}: ${value} vuelos`;
          },
        },
      },
    },
    cutout: '60%',
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  };

  return (
    <div className="relative">
      <Doughnut data={data} options={options} />
    </div>
  );
}