import React, { useState, useEffect } from 'react';
import { Plus, Plane } from 'lucide-react';
import { AircraftCard } from '../components/aircraft/AircraftCard';
import { Aircraft } from '../types/aircraft';
import { AircraftForm } from '../components/aircraft/AircraftForm';
import { useAuth } from '../contexts/AuthContext';
import { collection, query, where, getDocs, addDoc, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';

export default function AircraftPage() {
  const [showAddForm, setShowAddForm] = useState(false);
  const [aircraft, setAircraft] = useState<Aircraft[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<'all' | 'airplane' | 'drone'>('all');
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    const fetchAircraft = async () => {
      if (!user) return;

      try {
        const aircraftRef = collection(db, 'aircraft');
        const aircraftQuery = query(aircraftRef, where('userId', '==', user.uid));
        const snapshot = await getDocs(aircraftQuery);
        
        const aircraftList: Aircraft[] = [];
        snapshot.forEach((doc) => {
          aircraftList.push({ id: doc.id, ...doc.data() } as Aircraft);
        });
        
        setAircraft(aircraftList);
      } catch (error) {
        console.error('Error fetching aircraft:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAircraft();
  }, [user]);

  const handleAddAircraft = async (newAircraft: Omit<Aircraft, 'id' | 'userId' | 'status'>) => {
    if (!user?.uid) {
      console.error('No user found');
      return;
    }

    try {
      const aircraftRef = collection(db, 'aircraft');
      const aircraftData = {
        ...newAircraft,
        userId: user.uid,
        status: 'active' as const,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };

      const docRef = await addDoc(aircraftRef, aircraftData);
      const addedAircraft: Aircraft = {
        id: docRef.id,
        ...aircraftData
      };

      setAircraft(prev => [...prev, addedAircraft]);
      setShowAddForm(false);
    } catch (error) {
      console.error('Error adding aircraft:', error);
      throw new Error('Error al añadir la aeronave');
    }
  };

  const handleDeleteAircraft = async (aircraftToDelete: Aircraft) => {
    try {
      await deleteDoc(doc(db, 'aircraft', aircraftToDelete.id));
      setAircraft(prev => prev.filter(a => a.id !== aircraftToDelete.id));
    } catch (error) {
      console.error('Error deleting aircraft:', error);
      alert('Error al eliminar la aeronave');
    }
  };

  const handleStatusChange = async (aircraftToUpdate: Aircraft, newStatus: 'active' | 'inactive') => {
    try {
      const aircraftRef = doc(db, 'aircraft', aircraftToUpdate.id);
      await updateDoc(aircraftRef, {
        status: newStatus,
        updatedAt: new Date().toISOString()
      });
      
      setAircraft(prev => prev.map(a => 
        a.id === aircraftToUpdate.id 
          ? { ...a, status: newStatus }
          : a
      ));
    } catch (error) {
      console.error('Error updating aircraft status:', error);
      alert('Error al actualizar el estado de la aeronave');
    }
  };

  const filteredAircraft = aircraft.filter(a => 
    selectedCategory === 'all' ? true : a.category === selectedCategory
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <div className="relative h-[400px] -mx-4 -mt-4 overflow-hidden rounded-xl">
        <img
          className="w-full h-full object-cover"
          src="https://images.unsplash.com/photo-1474302770737-173ee21bab63?auto=format&fit=crop&q=80&w=1920"
          alt="Banner de aeronaves"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-blue-900/90 via-blue-800/70 to-transparent"></div>
        <div className="absolute inset-0 flex flex-col justify-center px-8">
          <div className="max-w-2xl">
            <h1 className="text-4xl font-bold text-white mb-4">
              Mi Flota Aérea
            </h1>
            <p className="text-xl text-blue-100 mb-8">
              Gestiona tu flota de aeronaves y drones de manera profesional.
            </p>
            <button
              onClick={() => setShowAddForm(true)}
              className="inline-flex items-center px-6 py-3 bg-white text-blue-900 text-base font-medium rounded-lg hover:bg-blue-50 transition-all duration-200 transform hover:scale-105 shadow-lg"
            >
              <Plus className="h-5 w-5 mr-2" />
              Añadir Nueva Aeronave
            </button>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
        <div className="border-b border-gray-200 px-6 py-4">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
            <div>
              <h2 className="text-xl font-semibold text-gray-900">Mi Flota</h2>
              <p className="text-sm text-gray-500 mt-1">Todas tus aeronaves en un solo lugar</p>
            </div>
            <div className="mt-4 sm:mt-0 flex flex-wrap gap-3">
              <button 
                onClick={() => setSelectedCategory('all')}
                className={`btn-secondary ${selectedCategory === 'all' ? 'bg-blue-50 text-blue-600 border-blue-200' : ''}`}
              >
                <Plane className="h-4 w-4 mr-2" />
                Todas
              </button>
              <button 
                onClick={() => setSelectedCategory('airplane')}
                className={`btn-secondary ${selectedCategory === 'airplane' ? 'bg-blue-50 text-blue-600 border-blue-200' : ''}`}
              >
                <Plane className="h-4 w-4 mr-2" />
                Aeronaves
              </button>
              <button 
                onClick={() => setSelectedCategory('drone')}
                className={`btn-secondary ${selectedCategory === 'drone' ? 'bg-blue-50 text-blue-600 border-blue-200' : ''}`}
              >
                <Plane className="h-4 w-4 mr-2" />
                Drones
              </button>
            </div>
          </div>
        </div>

        <div className="p-6">
          {filteredAircraft.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredAircraft.map((aircraft) => (
                <AircraftCard 
                  key={aircraft.id} 
                  aircraft={aircraft}
                  onDelete={handleDeleteAircraft}
                  onStatusChange={handleStatusChange}
                />
              ))}
            </div>
          ) : (
            <div className="text-center py-12">
              <div className="inline-flex items-center justify-center w-20 h-20 rounded-full bg-gradient-to-br from-blue-500 to-blue-600 text-white mb-6">
                <Plane className="h-10 w-10" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                No hay aeronaves registradas
              </h3>
              <p className="text-gray-500 mb-6 max-w-sm mx-auto">
                Comienza añadiendo tu primera aeronave o dron para empezar a registrar tus vuelos
              </p>
              <button
                onClick={() => setShowAddForm(true)}
                className="btn inline-flex items-center"
              >
                <Plus className="h-5 w-5 mr-2" />
                Añadir Aeronave
              </button>
            </div>
          )}
        </div>
      </div>

      <AircraftForm
        isOpen={showAddForm}
        onClose={() => setShowAddForm(false)}
        onSubmit={handleAddAircraft}
      />
    </div>
  );
}