import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Plane, 
  Clock, 
  Calendar, 
  Award, 
  PlaneTakeoff, 
  MapPin,
  ArrowRight,
  Loader2,
  AlertCircle
} from 'lucide-react';
import { useFlightStats } from '../hooks/useFlightStats';
import { useAuth } from '../contexts/AuthContext';
import { formatHours } from '../utils/timeFormat';

export function Dashboard() {
  const { user } = useAuth();
  const { stats, loading, error } = useFlightStats();

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[calc(100vh-16rem)]">
        <div className="flex flex-col items-center space-y-4">
          <Loader2 className="h-8 w-8 animate-spin text-blue-600" />
          <p className="text-gray-600">Cargando panel de control...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-[calc(100vh-16rem)]">
        <div className="text-center space-y-4">
          <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-red-100">
            <AlertCircle className="h-8 w-8 text-red-600" />
          </div>
          <p className="text-red-600">{error}</p>
          <button 
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Reintentar
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      {/* Welcome Banner */}
      <div className="relative h-[300px] -mx-4 -mt-4 overflow-hidden rounded-xl">
        <img
          className="w-full h-full object-cover object-center"
          src="https://images.unsplash.com/photo-1569629743817-70d8db6c323b?auto=format&fit=crop&q=80&w=1920"
          alt="Dashboard banner"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-blue-900/90 to-blue-800/70"></div>
        <div className="absolute inset-0 flex items-center px-8">
          <div className="max-w-3xl">
            <h1 className="text-4xl font-bold text-white mb-4">
              ¡Bienvenido, {user?.name || 'Piloto'}!
            </h1>
            <p className="text-xl text-blue-100 mb-8">
              Gestiona tu actividad aérea de forma profesional
            </p>
            <Link
              to="/mi-registro"
              className="inline-flex items-center px-6 py-3 bg-white text-blue-900 text-base font-medium rounded-lg hover:bg-blue-50 transition-all duration-200 transform hover:scale-105 shadow-lg"
            >
              Registrar Nuevo Vuelo
              <ArrowRight className="ml-2 h-5 w-5" />
            </Link>
          </div>
        </div>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <div className="bg-white rounded-xl p-6 border border-gray-200">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-blue-100 rounded-lg">
              <Clock className="h-5 w-5 text-blue-600" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Horas Totales</p>
              <p className="text-2xl font-bold text-gray-900">{formatHours(stats.totalHours)}</p>
              <p className="text-sm text-green-600">
                +{formatHours(stats.monthlyHours)} último mes
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl p-6 border border-gray-200">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-green-100 rounded-lg">
              <PlaneTakeoff className="h-5 w-5 text-green-600" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Vuelos Realizados</p>
              <p className="text-2xl font-bold text-gray-900">{stats.totalFlights}</p>
              <p className="text-sm text-green-600">
                +{stats.monthlyFlights} último mes
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl p-6 border border-gray-200">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-purple-100 rounded-lg">
              <MapPin className="h-5 w-5 text-purple-600" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Aeródromos</p>
              <p className="text-2xl font-bold text-gray-900">{stats.airports}</p>
              <p className="text-sm text-green-600">
                +{stats.newAirports} último mes
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl p-6 border border-gray-200">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-amber-100 rounded-lg">
              <Award className="h-5 w-5 text-amber-600" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Licencias</p>
              <p className="text-2xl font-bold text-gray-900">{stats.licenses}</p>
              {stats.expiringLicenses > 0 && (
                <p className="text-sm text-amber-600">
                  {stats.expiringLicenses} por renovar
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Quick Actions */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <Link 
          to="/mi-registro"
          className="bg-white rounded-xl p-6 border border-gray-200 hover:border-blue-300 hover:shadow-md transition-all group"
        >
          <div className="flex items-center justify-between mb-4">
            <div className="p-2 bg-blue-100 rounded-lg group-hover:bg-blue-600 transition-colors">
              <PlaneTakeoff className="h-5 w-5 text-blue-600 group-hover:text-white" />
            </div>
            <ArrowRight className="h-5 w-5 text-gray-400 group-hover:text-blue-600 transition-colors" />
          </div>
          <h3 className="font-semibold text-gray-900 mb-1">Registrar Vuelo</h3>
          <p className="text-sm text-gray-500">
            Añade un nuevo vuelo a tu registro
          </p>
        </Link>

        <Link 
          to="/aeronaves"
          className="bg-white rounded-xl p-6 border border-gray-200 hover:border-blue-300 hover:shadow-md transition-all group"
        >
          <div className="flex items-center justify-between mb-4">
            <div className="p-2 bg-blue-100 rounded-lg group-hover:bg-blue-600 transition-colors">
              <Plane className="h-5 w-5 text-blue-600 group-hover:text-white" />
            </div>
            <ArrowRight className="h-5 w-5 text-gray-400 group-hover:text-blue-600 transition-colors" />
          </div>
          <h3 className="font-semibold text-gray-900 mb-1">Gestionar Aeronaves</h3>
          <p className="text-sm text-gray-500">
            Administra tu flota de aeronaves
          </p>
        </Link>

        <Link 
          to="/estadisticas"
          className="bg-white rounded-xl p-6 border border-gray-200 hover:border-blue-300 hover:shadow-md transition-all group"
        >
          <div className="flex items-center justify-between mb-4">
            <div className="p-2 bg-blue-100 rounded-lg group-hover:bg-blue-600 transition-colors">
              <Calendar className="h-5 w-5 text-blue-600 group-hover:text-white" />
            </div>
            <ArrowRight className="h-5 w-5 text-gray-400 group-hover:text-blue-600 transition-colors" />
          </div>
          <h3 className="font-semibold text-gray-900 mb-1">Ver Estadísticas</h3>
          <p className="text-sm text-gray-500">
            Analiza tu actividad aérea
          </p>
        </Link>
      </div>

      {/* Recent Activity */}
      {stats.recentAirports.length > 0 && (
        <div className="bg-white rounded-xl border border-gray-200 overflow-hidden">
          <div className="p-6 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-gray-900">Aeródromos Recientes</h2>
            <p className="text-sm text-gray-500">
              Tus destinos más frecuentes
            </p>
          </div>
          <div className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {stats.recentAirports.map((airport) => (
                <div 
                  key={airport.code}
                  className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
                >
                  <div className="flex items-center space-x-3">
                    <div className="p-2 bg-blue-100 rounded-lg">
                      <MapPin className="h-4 w-4 text-blue-600" />
                    </div>
                    <div>
                      <p className="font-medium text-gray-900">{airport.code}</p>
                      <p className="text-sm text-gray-500">{airport.visits} visitas</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}