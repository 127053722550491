import React, { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { Shield, User, AlertCircle, Upload, Plus } from 'lucide-react';
import { LicenseModal } from '../components/profile/LicenseModal';
import { LicenseList } from '../components/profile/LicenseList';
import { ProfileInfo } from '../components/profile/ProfileInfo';
import { ProfilePhoto } from '../components/profile/ProfilePhoto';
import { useLicenses } from '../hooks/useLicenses';
import { License } from '../types/license';

export default function Profile() {
  const { user } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showLicenseModal, setShowLicenseModal] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState<License | null>(null);
  const [formData, setFormData] = useState({
    name: user?.name || '',
    phone: user?.phone || '',
    company: user?.company || '',
  });

  const { 
    licenses, 
    loading: loadingLicenses, 
    addLicense, 
    updateLicense, 
    deleteLicense 
  } = useLicenses();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user?.uid) return;

    setSaving(true);
    setError(null);

    try {
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        ...formData,
        updatedAt: new Date().toISOString()
      });

      setIsEditing(false);
    } catch (err) {
      console.error('Error updating profile:', err);
      setError('Error al actualizar el perfil. Por favor, inténtalo de nuevo.');
    } finally {
      setSaving(false);
    }
  };

  const handleLicenseSubmit = async (licenseData: Omit<License, 'id'>) => {
    try {
      if (selectedLicense) {
        await updateLicense(selectedLicense.id, licenseData);
      } else {
        await addLicense(licenseData);
      }
      setShowLicenseModal(false);
      setSelectedLicense(null);
    } catch (error) {
      console.error('Error handling license:', error);
    }
  };

  const handleDeleteLicense = async (licenseId: string) => {
    if (!window.confirm('¿Estás seguro de que deseas eliminar esta licencia?')) {
      return;
    }

    try {
      await deleteLicense(licenseId);
    } catch (error) {
      console.error('Error deleting license:', error);
    }
  };

  if (!user) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center space-y-4">
          <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-red-100">
            <AlertCircle className="h-8 w-8 text-red-600" />
          </div>
          <p className="text-red-600">No se ha podido cargar el perfil</p>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      {/* Banner principal con foto de perfil integrada */}
      <div className="relative h-[300px] -mx-4 -mt-4 overflow-hidden rounded-xl">
        <img
          className="w-full h-full object-cover"
          src="https://images.unsplash.com/photo-1523286877159-d9636545890c?auto=format&fit=crop&q=80&w=1920"
          alt="Profile banner"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-blue-900/90 via-blue-800/70 to-transparent"></div>
        <div className="absolute inset-0 flex items-center px-8">
          <div className="flex items-center space-x-8">
            {/* Componente de foto de perfil */}
            <div className="relative">
              {user?.photoURL ? (
                <img
                  src={user.photoURL}
                  alt="Foto de perfil"
                  className="w-32 h-32 rounded-full object-cover border-4 border-white shadow-lg"
                />
              ) : (
                <div className="w-32 h-32 rounded-full bg-blue-100 border-4 border-white shadow-lg flex items-center justify-center">
                  <User className="w-16 h-16 text-blue-600" />
                </div>
              )}
              <button
                onClick={() => document.getElementById('profile-photo-input')?.click()}
                className="absolute bottom-0 right-0 p-2 bg-white rounded-full shadow-lg hover:bg-gray-50 transition-colors"
              >
                <Upload className="w-5 h-5 text-blue-600" />
              </button>
            </div>
            
            {/* Información del usuario */}
            <div>
              <h1 className="text-4xl font-bold text-white mb-2">
                {user?.name || 'Usuario'}
              </h1>
              <p className="text-xl text-blue-100 mb-2">Mi Perfil</p>
              <p className="text-sm text-blue-200">
                {user?.email}
              </p>
            </div>
          </div>
        </div>
      </div>

      {error && (
        <div className="bg-red-50 border border-red-200 rounded-xl p-4 flex items-center text-red-600">
          <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
          <p>{error}</p>
        </div>
      )}

      {/* Contenido principal */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* Columna de información personal */}
        <div className="lg:col-span-2">
          <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
            <ProfileInfo
              user={user}
              isEditing={isEditing}
              saving={saving}
              formData={formData}
              onFormChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))}
              onSubmit={handleSubmit}
              onCancel={() => {
                setIsEditing(false);
                setFormData({
                  name: user?.name || '',
                  phone: user?.phone || '',
                  company: user?.company || '',
                });
              }}
              onEdit={() => setIsEditing(true)}
            />
          </div>
        </div>

        {/* Columna de licencias */}
        <div className="lg:col-span-1">
          <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
            <div className="p-6 border-b border-gray-200">
              <div className="flex justify-between items-center">
                <h2 className="text-lg font-semibold text-gray-900">Licencias y Certificaciones</h2>
                <button
                  onClick={() => setShowLicenseModal(true)}
                  className="inline-flex items-center px-3 py-1.5 border border-blue-600 text-blue-600 hover:bg-blue-50 rounded-lg text-sm font-medium transition-colors"
                >
                  <Plus className="h-4 w-4 mr-1" />
                  Añadir
                </button>
              </div>
            </div>
            
            <LicenseList
              licenses={licenses}
              onEdit={(license) => {
                setSelectedLicense(license);
                setShowLicenseModal(true);
              }}
              onDelete={handleDeleteLicense}
            />
          </div>
        </div>
      </div>

      {/* Modal de licencias */}
      {showLicenseModal && (
        <LicenseModal
          isOpen={showLicenseModal}
          onClose={() => {
            setShowLicenseModal(false);
            setSelectedLicense(null);
          }}
          onSubmit={handleLicenseSubmit}
          license={selectedLicense}
        />
      )}

      {/* Input oculto para la foto de perfil */}
      <ProfilePhoto />
    </div>
  );
}