import React, { useState } from 'react';
import { Plane, Clock, Calendar, MoreVertical, Trash2, Ban } from 'lucide-react';
import { Aircraft } from '../../types/aircraft';
import { useFlightStats } from '../../hooks/useFlightStats';
import { formatHours } from '../../utils/timeFormat';

interface AircraftCardProps {
  aircraft: Aircraft;
  onDelete: (aircraft: Aircraft) => void;
  onStatusChange: (aircraft: Aircraft, newStatus: 'active' | 'inactive') => void;
}

export function AircraftCard({ aircraft, onDelete, onStatusChange }: AircraftCardProps) {
  const [showMenu, setShowMenu] = useState(false);
  const { getAircraftStats } = useFlightStats();
  const stats = getAircraftStats(aircraft.id);
  
  const getDefaultImage = (category: string) => {
    return category === 'airplane' 
      ? 'https://images.unsplash.com/photo-1474302770737-173ee21bab63?auto=format&fit=crop&q=80&w=1920'
      : 'https://images.unsplash.com/photo-1473968512647-3e447244af8f?auto=format&fit=crop&q=80&w=1920';
  };

  const getTypeIcon = () => {
    switch (aircraft.type) {
      case 'avion':
        return <Plane className="h-4 w-4" />;
      case 'helicoptero':
        return <Plane className="h-4 w-4 rotate-90" />;
      case 'autogiro':
        return <Plane className="h-4 w-4" />;
      case 'ala-fija':
        return <Plane className="h-4 w-4" />;
      case 'multirrotor':
        return <Plane className="h-4 w-4" />;
      default:
        return <Plane className="h-4 w-4" />;
    }
  };

  const handleAction = async (action: 'delete' | 'status') => {
    if (action === 'delete') {
      if (stats.totalFlights > 0) {
        alert('No se puede eliminar una aeronave con vuelos registrados. Puede marcarla como inactiva en su lugar.');
        return;
      }
      if (window.confirm('¿Está seguro de que desea eliminar esta aeronave? Esta acción no se puede deshacer.')) {
        onDelete(aircraft);
      }
    } else if (action === 'status') {
      const newStatus = aircraft.status === 'active' ? 'inactive' : 'active';
      const message = newStatus === 'inactive' 
        ? '¿Está seguro de que desea dar de baja esta aeronave?' 
        : '¿Está seguro de que desea reactivar esta aeronave?';
      
      if (window.confirm(message)) {
        onStatusChange(aircraft, newStatus);
      }
    }
    setShowMenu(false);
  };

  return (
    <div className={`group bg-white rounded-xl shadow-sm transition-all duration-300 overflow-hidden border ${
      aircraft.status === 'active' ? 'border-gray-200' : 'border-red-200 bg-red-50/10'
    }`}>
      <div className="aspect-video w-full overflow-hidden relative">
        <img
          src={aircraft.imageUrl || getDefaultImage(aircraft.category)}
          alt={`${aircraft.model}`}
          className={`w-full h-full object-cover transition-transform duration-500 ${
            aircraft.status === 'inactive' ? 'grayscale' : 'group-hover:scale-105'
          }`}
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/20 to-transparent"></div>
        
        {aircraft.status === 'inactive' && (
          <div className="absolute top-4 right-4 px-3 py-1 bg-red-500 text-white text-sm font-medium rounded-full">
            De Baja
          </div>
        )}

        <div className="absolute bottom-0 left-0 right-0 p-4">
          <div className="flex items-center space-x-2 mb-2">
            <span className="inline-flex items-center px-2.5 py-1 bg-blue-600/90 text-white text-xs font-medium rounded-full">
              {getTypeIcon()}
              <span className="ml-1.5">{aircraft.category === 'airplane' ? 'Aeronave' : 'Dron'}</span>
            </span>
            <span className="inline-flex items-center px-2.5 py-1 bg-white/90 text-gray-900 text-xs font-medium rounded-full">
              {aircraft.type}
            </span>
          </div>
          <h3 className="text-xl font-bold text-white mb-1">{aircraft.registration}</h3>
          <p className="text-gray-200">{aircraft.model}</p>
        </div>

        <div className="absolute top-4 right-4">
          <div className="relative">
            <button
              onClick={() => setShowMenu(!showMenu)}
              className="p-2 text-white hover:bg-white/10 rounded-lg transition-colors"
            >
              <MoreVertical className="h-5 w-5" />
            </button>

            {showMenu && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200 py-1 z-10">
                {stats.totalFlights === 0 ? (
                  <button
                    onClick={() => handleAction('delete')}
                    className="w-full flex items-center px-4 py-2 text-sm text-red-600 hover:bg-red-50"
                  >
                    <Trash2 className="h-4 w-4 mr-2" />
                    Eliminar
                  </button>
                ) : (
                  <button
                    onClick={() => handleAction('status')}
                    className="w-full flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                  >
                    <Ban className="h-4 w-4 mr-2" />
                    {aircraft.status === 'active' ? 'Dar de Baja' : 'Reactivar'}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      
      <div className="p-6">
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col space-y-1">
            <span className="text-xs text-gray-500">Último Vuelo</span>
            <span className="text-sm font-medium text-gray-900">
              {stats.lastFlight ? new Date(stats.lastFlight).toLocaleDateString() : 'Sin vuelos'}
            </span>
          </div>
          <div className="flex flex-col space-y-1">
            <span className="text-xs text-gray-500">Horas Totales</span>
            <span className="text-sm font-medium text-gray-900">{formatHours(stats.totalHours)}</span>
          </div>
          <div className="flex flex-col space-y-1">
            <span className="text-xs text-gray-500">Total Vuelos</span>
            <span className="text-sm font-medium text-gray-900">{stats.totalFlights}</span>
          </div>
          <div className="flex flex-col space-y-1">
            <span className="text-xs text-gray-500">Número de Serie</span>
            <span className="text-sm font-medium text-gray-900">{aircraft.serialNumber}</span>
          </div>
        </div>
      </div>
    </div>
  );
}