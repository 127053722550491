import { useState, useEffect } from 'react';
import { collection, query, getDocs, addDoc, updateDoc, deleteDoc, doc, orderBy } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { License } from '../types/license';
import { useAuth } from '../contexts/AuthContext';

export function useLicenses() {
  const [licenses, setLicenses] = useState<License[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();

  const fetchLicenses = async () => {
    if (!user?.uid) {
      setLoading(false);
      return;
    }

    try {
      const licensesRef = collection(db, `users/${user.uid}/licenses`);
      const licensesQuery = query(licensesRef, orderBy('createdAt', 'desc'));
      const licensesSnapshot = await getDocs(licensesQuery);
      
      const licensesList = licensesSnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data(),
          // Ensure dates are valid
          issueDate: doc.data().issueDate || new Date().toISOString(),
          expiryDate: doc.data().expiryDate || new Date().toISOString(),
          createdAt: doc.data().createdAt || new Date().toISOString(),
          updatedAt: doc.data().updatedAt || new Date().toISOString()
        } as License))
        .filter(license => 
          // Filter out invalid licenses
          license.type && 
          license.number && 
          !isNaN(new Date(license.expiryDate).getTime())
        );
      
      setLicenses(licensesList);
      setError(null);
    } catch (err) {
      console.error('Error fetching licenses:', err);
      setError('Error al cargar las licencias');
      setLicenses([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLicenses();
  }, [user?.uid]);

  const addLicense = async (licenseData: Omit<License, 'id'>) => {
    if (!user?.uid) return;

    try {
      const licensesRef = collection(db, `users/${user.uid}/licenses`);
      const newLicense = {
        ...licenseData,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };

      // Validate dates before saving
      if (isNaN(new Date(newLicense.issueDate).getTime()) || 
          isNaN(new Date(newLicense.expiryDate).getTime())) {
        throw new Error('Fechas inválidas');
      }

      const docRef = await addDoc(licensesRef, newLicense);
      const license = { id: docRef.id, ...newLicense };
      setLicenses(prev => [license, ...prev]);
      return license;
    } catch (error) {
      console.error('Error adding license:', error);
      throw new Error('Error al añadir la licencia');
    }
  };

  const updateLicense = async (licenseId: string, licenseData: Omit<License, 'id'>) => {
    if (!user?.uid) return;

    try {
      // Validate dates before updating
      if (isNaN(new Date(licenseData.issueDate).getTime()) || 
          isNaN(new Date(licenseData.expiryDate).getTime())) {
        throw new Error('Fechas inválidas');
      }

      const licenseRef = doc(db, `users/${user.uid}/licenses`, licenseId);
      const updatedLicense = {
        ...licenseData,
        updatedAt: new Date().toISOString()
      };

      await updateDoc(licenseRef, updatedLicense);
      setLicenses(prev => prev.map(license => 
        license.id === licenseId 
          ? { ...license, ...updatedLicense }
          : license
      ));
    } catch (error) {
      console.error('Error updating license:', error);
      throw new Error('Error al actualizar la licencia');
    }
  };

  const deleteLicense = async (licenseId: string) => {
    if (!user?.uid) return;

    try {
      const licenseRef = doc(db, `users/${user.uid}/licenses`, licenseId);
      await deleteDoc(licenseRef);
      setLicenses(prev => prev.filter(license => license.id !== licenseId));
    } catch (error) {
      console.error('Error deleting license:', error);
      throw new Error('Error al eliminar la licencia');
    }
  };

  return {
    licenses,
    loading,
    error,
    addLicense,
    updateLicense,
    deleteLicense,
    refresh: fetchLicenses
  };
}