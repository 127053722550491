import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

interface DayNightChartProps {
  day: number;
  night: number;
}

export function DayNightChart({ day, night }: DayNightChartProps) {
  const data = {
    labels: ['Día', 'Noche'],
    datasets: [
      {
        data: [day, night],
        backgroundColor: [
          'rgba(245, 158, 11, 0.8)', // amber-500
          'rgba(99, 102, 241, 0.8)', // indigo-500
        ],
        borderColor: [
          'rgb(245, 158, 11)',
          'rgb(99, 102, 241)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          padding: 20,
          usePointStyle: true,
        },
      },
      tooltip: {
        callbacks: {
          label: function(context: any) {
            const label = context.label || '';
            const value = context.raw || 0;
            const total = day + night;
            const percentage = ((value / total) * 100).toFixed(1);
            return `${label}: ${value} vuelos (${percentage}%)`;
          },
        },
      },
    },
    cutout: '60%',
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  };

  return (
    <div className="relative">
      <Doughnut data={data} options={options} />
    </div>
  );
}