import React from 'react';
import { Pencil, Trash2 } from 'lucide-react';
import { License } from '../../types/license';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

interface LicenseListProps {
  licenses: License[];
  onEdit: (license: License) => void;
  onDelete: (licenseId: string) => void;
}

export function LicenseList({ licenses, onEdit, onDelete }: LicenseListProps) {
  // Filter out any invalid licenses
  const validLicenses = licenses.filter(license => 
    license.id && 
    license.type && 
    license.number && 
    license.expiryDate && 
    !isNaN(new Date(license.expiryDate).getTime())
  );

  if (validLicenses.length === 0) {
    return (
      <div className="text-center py-8">
        <p className="text-gray-500">No hay licencias registradas</p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {validLicenses.map((license) => (
        <div key={license.id} className="flex justify-between items-center p-4 bg-gray-50 rounded-lg">
          <div>
            <h3 className="font-medium text-gray-900">{license.type}</h3>
            <p className="text-sm text-gray-500">Número: {license.number}</p>
            <p className="text-sm text-gray-500">
              Válida hasta: {format(new Date(license.expiryDate), 'dd MMMM yyyy', { locale: es })}
            </p>
          </div>
          <div className="flex space-x-2">
            <button
              onClick={() => onEdit(license)}
              className="p-2 text-gray-400 hover:text-blue-500 hover:bg-blue-50 rounded-lg transition-colors"
              title="Editar licencia"
            >
              <Pencil className="h-5 w-5" />
            </button>
            <button
              onClick={() => onDelete(license.id)}
              className="p-2 text-gray-400 hover:text-red-500 hover:bg-red-50 rounded-lg transition-colors"
              title="Eliminar licencia"
            >
              <Trash2 className="h-5 w-5" />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}