import React from 'react';
import { ExternalLink } from 'lucide-react';

export function DronesEnaire() {
  return (
    <div className="space-y-8">
      {/* Banner */}
      <div className="relative h-[300px] -mx-4 -mt-4 overflow-hidden rounded-xl">
        <img
          className="w-full h-full object-cover"
          src="https://images.unsplash.com/photo-1473968512647-3e447244af8f?auto=format&fit=crop&q=80&w=2070"
          alt="Dron volando"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-blue-900/90 to-blue-800/70">
        </div>
        <div className="absolute inset-0 flex items-center px-8">
          <div className="max-w-3xl">
            <h1 className="text-4xl font-bold text-white mb-4">
              Plan de Vuelo - Drones Enaire
            </h1>
            <p className="text-xl text-blue-100">
              Planifica tus operaciones con drones de forma segura y profesional
            </p>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="bg-white p-8 rounded-lg shadow-md">
        <a 
          href="https://drones.enaire.es" 
          target="_blank" 
          rel="noopener noreferrer"
          className="inline-flex items-center px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition-colors duration-200"
        >
          <span>Abrir Drones Enaire</span>
          <ExternalLink className="w-5 h-5 ml-2" />
        </a>

        <div className="mt-6 p-4 bg-blue-50 rounded-lg">
          <h2 className="text-lg font-semibold text-blue-800 mb-2">¿Qué es Drones Enaire?</h2>
          <p className="text-blue-700">
            Drones Enaire es la aplicación web oficial de ENAIRE que permite a los operadores de drones:
          </p>
          <ul className="list-disc list-inside mt-2 text-blue-700 space-y-1">
            <li>Planificar vuelos de drones de forma segura</li>
            <li>Consultar zonas restringidas y espacios aéreos controlados</li>
            <li>Obtener información meteorológica relevante</li>
            <li>Gestionar permisos de vuelo cuando sean necesarios</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
