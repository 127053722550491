import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { 
  User as FirebaseUser,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  AuthError,
  updateDoc
} from 'firebase/auth';
import { doc, setDoc, getDoc, serverTimestamp, updateDoc as updateDocFirestore } from 'firebase/firestore';
import { auth, db } from '../lib/firebase';
import { useNavigate } from 'react-router-dom';

interface User extends FirebaseUser {
  role?: 'admin' | 'user';
  name?: string;
  phone?: string;
  address?: string;
  company?: string;
  status?: 'active' | 'suspended';
  lastLoginAt?: string;
}

interface AuthContextType {
  user: User | null;
  login: (email: string, password: string) => Promise<void>;
  register: (email: string, password: string, userData: Omit<User, keyof FirebaseUser>) => Promise<void>;
  logout: () => Promise<void>;
  loading: boolean;
  sendVerificationEmail: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  updateUserData: (data: Partial<User>) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | null>(null);

function getErrorMessage(error: AuthError): string {
  switch (error.code) {
    case 'auth/invalid-email':
      return 'El correo electrónico no es válido';
    case 'auth/user-disabled':
      return 'Esta cuenta ha sido deshabilitada';
    case 'auth/user-not-found':
      return 'No existe una cuenta con este correo electrónico';
    case 'auth/wrong-password':
      return 'Contraseña incorrecta';
    case 'auth/too-many-requests':
      return 'Demasiados intentos fallidos. Por favor, inténtalo más tarde';
    case 'auth/network-request-failed':
      return 'Error de conexión. Verifica tu conexión a internet';
    case 'auth/email-already-in-use':
      return 'Ya existe una cuenta con este correo electrónico';
    case 'auth/weak-password':
      return 'La contraseña debe tener al menos 6 caracteres';
    default:
      return 'Error al iniciar sesión. Por favor, inténtalo de nuevo';
  }
}

export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        try {
          const userDocRef = doc(db, 'users', firebaseUser.uid);
          const userDoc = await getDoc(userDocRef);
          
          if (!userDoc.exists()) {
            const initialUserData = {
              email: firebaseUser.email,
              role: 'user',
              status: 'active',
              createdAt: serverTimestamp(),
              updatedAt: serverTimestamp(),
              lastLoginAt: new Date().toISOString()
            };
            await setDoc(userDocRef, initialUserData);
            
            setUser({
              ...firebaseUser,
              ...initialUserData
            });
          } else {
            const userData = userDoc.data();
            
            if (userData.status === 'suspended' && userData.role !== 'admin') {
              await firebaseSignOut(auth);
              setUser(null);
              throw new Error('Tu cuenta está suspendida. Contacta con el administrador.');
            }

            await setDoc(userDocRef, {
              ...userData,
              lastLoginAt: new Date().toISOString(),
              updatedAt: serverTimestamp()
            }, { merge: true });

            setUser({
              ...firebaseUser,
              ...userData
            });
          }
        } catch (error) {
          console.error('Error initializing user data:', error);
          await firebaseSignOut(auth);
          setUser(null);
          if (error instanceof Error) {
            throw error;
          }
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  const login = async (email: string, password: string) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      
      if (!userCredential.user.emailVerified) {
        throw new Error('Por favor, verifica tu correo electrónico antes de iniciar sesión.');
      }

      const userDoc = await getDoc(doc(db, 'users', userCredential.user.uid));
      const userData = userDoc.data();
      
      if (userData?.status === 'suspended' && userData?.role !== 'admin') {
        throw new Error('Tu cuenta está suspendida. Contacta con el administrador.');
      }

      setUser({
        ...userCredential.user,
        ...userData
      });

      // Recuperar credenciales guardadas
      const rememberedCredentials = localStorage.getItem('rememberedCredentials');
      if (rememberedCredentials) {
        const { rememberMe } = JSON.parse(rememberedCredentials);
        if (rememberMe) {
          localStorage.setItem('rememberedCredentials', JSON.stringify({
            email,
            password,
            rememberMe: true
          }));
        }
      }

      navigate('/dashboard');
    } catch (error) {
      console.error('Error al iniciar sesión:', error);
      if (error instanceof Error && 'code' in error) {
        throw new Error(getErrorMessage(error as AuthError));
      } else if (error instanceof Error) {
        throw error;
      } else {
        throw new Error('Error al iniciar sesión. Por favor, inténtalo de nuevo');
      }
    }
  };

  const register = async (email: string, password: string, userData: Omit<User, keyof FirebaseUser>) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      await sendEmailVerification(userCredential.user);
      
      await setDoc(doc(db, 'users', userCredential.user.uid), {
        email,
        role: 'user',
        status: 'active',
        ...userData,
        emailVerified: false,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        lastLoginAt: new Date().toISOString()
      });

      await firebaseSignOut(auth);
      setUser(null);

      throw new Error('Se ha enviado un correo de verificación. Por favor, verifica tu email antes de iniciar sesión.');
    } catch (error) {
      console.error('Error al registrar usuario:', error);
      if (error instanceof Error && 'code' in error) {
        throw new Error(getErrorMessage(error as AuthError));
      } else if (error instanceof Error) {
        throw error;
      } else {
        throw new Error('Error al registrar usuario. Por favor, inténtalo de nuevo');
      }
    }
  };

  const sendVerificationEmail = async () => {
    if (auth.currentUser && !auth.currentUser.emailVerified) {
      await sendEmailVerification(auth.currentUser);
    }
  };

  const resetPassword = async (email: string) => {
    await sendPasswordResetEmail(auth, email);
  };

  const updateUserData = async (data: Partial<User>) => {
    if (!user?.uid) return;
    
    try {
      const userRef = doc(db, 'users', user.uid);
      await updateDocFirestore(userRef, {
        ...data,
        updatedAt: new Date().toISOString()
      });

      setUser(prev => prev ? { ...prev, ...data } : null);
    } catch (error) {
      console.error('Error updating user data:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await firebaseSignOut(auth);
      setUser(null);
      navigate('/');
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
      throw error;
    }
  };

  const value = {
    user,
    login,
    register,
    logout,
    loading,
    sendVerificationEmail,
    resetPassword,
    updateUserData
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}