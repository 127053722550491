import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { formatHours } from '../../utils/timeFormat';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface AircraftStats {
  type: string;
  hours: number;
  flights: number;
}

interface AircraftStatsChartProps {
  stats: AircraftStats[];
}

export function AircraftStatsChart({ stats }: AircraftStatsChartProps) {
  const data = {
    labels: stats.map(stat => stat.type),
    datasets: [
      {
        label: 'Horas de Vuelo',
        data: stats.map(stat => stat.hours),
        backgroundColor: 'rgba(59, 130, 246, 0.8)', // blue-500
        borderColor: 'rgb(59, 130, 246)',
        borderWidth: 1,
        borderRadius: 4,
      },
      {
        label: 'Vuelos',
        data: stats.map(stat => stat.flights),
        backgroundColor: 'rgba(16, 185, 129, 0.8)', // green-500
        borderColor: 'rgb(16, 185, 129)',
        borderWidth: 1,
        borderRadius: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {
          usePointStyle: true,
          padding: 20,
        },
      },
      tooltip: {
        callbacks: {
          label: function(context: any) {
            const label = context.dataset.label || '';
            const value = context.raw || 0;
            if (label === 'Horas de Vuelo') {
              return `${label}: ${formatHours(value)}`;
            }
            return `${label}: ${value}`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
          color: 'rgba(0, 0, 0, 0.1)',
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    animations: {
      y: {
        duration: 1000,
        easing: 'easeOutQuart',
      },
    },
    barThickness: 'flex' as const,
    maxBarThickness: 30,
  };

  return <Bar data={data} options={options} />;
}