import React, { useState, useRef } from 'react';
import { User, Upload, Loader2 } from 'lucide-react';
import { storage } from '../../lib/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useAuth } from '../../contexts/AuthContext';

const MAX_FILE_SIZE = 300 * 1024; // 300KB

const compressImage = (file: File): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target?.result as string;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;

        // Calcular las nuevas dimensiones manteniendo la proporción
        const maxDimension = 800;
        if (width > height && width > maxDimension) {
          height = (height * maxDimension) / width;
          width = maxDimension;
        } else if (height > maxDimension) {
          width = (width * maxDimension) / height;
          height = maxDimension;
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, width, height);

        // Comprimir con calidad progresiva hasta alcanzar el tamaño deseado
        let quality = 0.7;
        let blob = canvas.toDataURL('image/jpeg', quality);
        
        while (blob.length > MAX_FILE_SIZE * 1.37 && quality > 0.1) {
          quality -= 0.1;
          blob = canvas.toDataURL('image/jpeg', quality);
        }

        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error('Error al comprimir la imagen'));
            }
          },
          'image/jpeg',
          quality
        );
      };
      img.onerror = () => {
        reject(new Error('Error al cargar la imagen'));
      };
    };
    reader.onerror = () => {
      reject(new Error('Error al leer el archivo'));
    };
  });
};

export function ProfilePhoto() {
  const { user, updateUserData } = useAuth();
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !user?.uid) return;

    // Validar el tipo de archivo
    if (!file.type.startsWith('image/')) {
      setError('Por favor, selecciona una imagen válida (JPG o PNG)');
      return;
    }

    setUploading(true);
    setError(null);

    try {
      let imageToUpload: Blob = file;

      // Si el archivo excede el límite, intentar comprimirlo
      if (file.size > MAX_FILE_SIZE) {
        try {
          imageToUpload = await compressImage(file);
          if (imageToUpload.size > MAX_FILE_SIZE) {
            setError('La imagen es demasiado grande. Por favor, selecciona una imagen más pequeña (máx. 300KB)');
            setUploading(false);
            return;
          }
        } catch (err) {
          console.error('Error compressing image:', err);
          setError('Error al procesar la imagen. Por favor, intenta con otra.');
          setUploading(false);
          return;
        }
      }

      // Crear una referencia única para la imagen
      const storageRef = ref(storage, `profile-photos/${user.uid}/${Date.now()}-${file.name}`);
      
      // Subir la imagen
      const snapshot = await uploadBytes(storageRef, imageToUpload);
      
      // Obtener la URL de la imagen
      const photoURL = await getDownloadURL(snapshot.ref);

      // Actualizar el documento del usuario con la nueva URL
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        photoURL,
        updatedAt: new Date().toISOString()
      });

      // Actualizar el estado local del usuario
      updateUserData({ photoURL });
    } catch (err) {
      console.error('Error uploading photo:', err);
      setError('Error al subir la imagen. Por favor, inténtalo de nuevo.');
    } finally {
      setUploading(false);
    }
  };

  return (
    <input
      id="profile-photo-input"
      type="file"
      accept="image/jpeg,image/png"
      onChange={handleFileChange}
      className="hidden"
    />
  );
}
