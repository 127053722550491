import React, { useState, useEffect } from 'react';
import { Search, MapPin, ChevronLeft, ChevronRight } from 'lucide-react';
import aerodromosData from '../aerodromos/aerodromos.json';

interface Aerodromo {
  Position: string;
  Codigo: string;
  Tipo: string;
  CAV: string;
  Nombre: string;
  OACI: string;
  Provincia: string;
  Frecuencia: string;
  Dimensiones: string;
  Telefono: string;
}

export function Aerodromos() {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredAerodromos, setFilteredAerodromos] = useState<Aerodromo[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  useEffect(() => {
    const filtered = aerodromosData.filter((aerodromo: Aerodromo) => {
      const searchLower = searchTerm.toLowerCase();
      return (
        aerodromo.Nombre.toLowerCase().includes(searchLower) ||
        aerodromo.OACI.toLowerCase().includes(searchLower) ||
        aerodromo.Provincia.toLowerCase().includes(searchLower) ||
        aerodromo.Codigo.toLowerCase().includes(searchLower)
      );
    });
    setFilteredAerodromos(filtered);
    setCurrentPage(1); // Reset to first page when search changes
  }, [searchTerm]);

  // Calculate pagination
  const totalPages = Math.ceil(filteredAerodromos.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentAerodromos = filteredAerodromos.slice(startIndex, endIndex);

  // Generate page numbers array
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  return (
    <div className="space-y-8">
      {/* Banner */}
      <div className="relative h-[300px] -mx-4 -mt-4 overflow-hidden rounded-xl">
        <img
          className="w-full h-full object-cover"
          src="https://images.unsplash.com/photo-1436491865332-7a61a109cc05?auto=format&fit=crop&q=80"
          alt="Vista aérea de aeropuerto"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-blue-900/90 to-blue-800/70">
        </div>
        <div className="absolute inset-0 flex items-center px-8">
          <div className="max-w-3xl">
            <h1 className="text-4xl font-bold text-white mb-4">
              Aeródromos
            </h1>
            <p className="text-xl text-blue-100">
              Consulta información detallada de aeródromos
            </p>
          </div>
        </div>
      </div>

      {/* Search Bar and Results Count */}
      <div className="space-y-4">
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Search className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            placeholder="Buscar por nombre, OACI, provincia o código..."
            className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="text-sm text-gray-600">
          Mostrando {currentAerodromos.length} de {filteredAerodromos.length} aeródromos
        </div>
      </div>

      {/* Aerodromos Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {currentAerodromos.map((aerodromo) => (
          <div key={aerodromo.Codigo} className="bg-white rounded-lg border border-gray-200 p-6 hover:shadow-lg transition-shadow">
            <div className="flex items-start space-x-4">
              <div className="p-2 bg-blue-100 rounded-lg">
                <MapPin className="h-6 w-6 text-blue-600" />
              </div>
              <div className="flex-1">
                <h3 className="text-lg font-semibold text-gray-900">{aerodromo.Nombre}</h3>
                <div className="mt-2 space-y-2">
                  {aerodromo.OACI && (
                    <p className="text-sm">
                      <span className="font-medium text-gray-500">OACI:</span>{' '}
                      <span className="text-gray-900">{aerodromo.OACI}</span>
                    </p>
                  )}
                  <p className="text-sm">
                    <span className="font-medium text-gray-500">Provincia:</span>{' '}
                    <span className="text-gray-900">{aerodromo.Provincia}</span>
                  </p>
                  <p className="text-sm">
                    <span className="font-medium text-gray-500">Tipo:</span>{' '}
                    <span className="text-gray-900">{aerodromo.Tipo}</span>
                  </p>
                  <p className="text-sm">
                    <span className="font-medium text-gray-500">Frecuencia:</span>{' '}
                    <span className="text-gray-900">{aerodromo.Frecuencia}</span>
                  </p>
                  <p className="text-sm">
                    <span className="font-medium text-gray-500">Dimensiones:</span>{' '}
                    <span className="text-gray-900">{aerodromo.Dimensiones}</span>
                  </p>
                  <p className="text-sm">
                    <span className="font-medium text-gray-500">Teléfono:</span>{' '}
                    <span className="text-gray-900">{aerodromo.Telefono}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="flex justify-center items-center space-x-2">
          <button
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="p-2 rounded-lg border border-gray-300 disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
          >
            <ChevronLeft className="h-5 w-5 text-gray-600" />
          </button>
          
          {getPageNumbers().map(pageNum => (
            <button
              key={pageNum}
              onClick={() => setCurrentPage(pageNum)}
              className={`px-4 py-2 rounded-lg border ${
                currentPage === pageNum
                  ? 'bg-blue-600 text-white border-blue-600'
                  : 'border-gray-300 text-gray-600 hover:bg-gray-50'
              }`}
            >
              {pageNum}
            </button>
          ))}

          <button
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className="p-2 rounded-lg border border-gray-300 disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
          >
            <ChevronRight className="h-5 w-5 text-gray-600" />
          </button>
        </div>
      )}
    </div>
  );
}
