export function formatHours(hours: number): string {
  if (!hours || isNaN(hours)) return '0:00h';
  
  const totalMinutes = Math.round(hours * 60);
  const wholeHours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  
  return `${wholeHours}:${minutes.toString().padStart(2, '0')}h`;
}

export function parseTimeString(timeString: string): number {
  if (!timeString) return 0;
  const [hours, minutes] = timeString.split(':').map(Number);
  if (isNaN(hours) || isNaN(minutes)) return 0;
  return hours + (minutes / 60);
}

export function formatTimeString(hours: number): string {
  if (!hours || isNaN(hours)) return '0:00';
  const wholeHours = Math.floor(hours);
  const minutes = Math.round((hours - wholeHours) * 60);
  return `${wholeHours}:${minutes.toString().padStart(2, '0')}`;
}

export function validateTimeString(timeString: string): boolean {
  if (!timeString) return false;
  const [hours, minutes] = timeString.split(':').map(Number);
  return !isNaN(hours) && !isNaN(minutes) && minutes >= 0 && minutes < 60;
}